import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../_type/api-response.type';
import { Set } from '../model/set-model/set.model';
import { BaseService } from '../../../../_base/base.service';
import { CustomerService } from '../../customer-data/services/customer.service';

@Injectable({ providedIn: 'root' })
export class SetService extends BaseService {
  private readonly apiPath =
    environment.baseUrl +
    (environment.usePort ? ':' + environment.port : '') +
    '/' + environment.apiHead + 'set';

  protected readonly jwt_token = localStorage.getItem('JWT_TOKEN');

  private currentSetSbj = new BehaviorSubject<Set | undefined>(undefined);
  public get onCurrentSetChanged() {
    return this.currentSetSbj.asObservable()
      .pipe(skipWhile(set => !set));
  }
  public get currentSet() {
    return this.currentSetSbj.value;
  }

  public metricFlag = 1;

  constructor(
    logger: NGXLogger,
    private readonly http: HttpClient,
    private readonly customer: CustomerService,
  ) { super(logger); }

  setSet(set: Set) {
    this.currentSetSbj.next(set);
  }

  getSet(setId: string, withJwtToken?: boolean) {
    // GET /set/[_id]?origin=kiosk
    if (!withJwtToken) {
      return this.http
        .get<ApiResponse<Set>>(this.apiPath + '/' + setId + '?origin=kiosk')
        .pipe(map(res => res.data));
    } else {
      return this.http
        .get<ApiResponse<Set>>(this.apiPath + '/' + setId, {
          headers: {
            Authorization: `Bearer ${this.jwt_token}`,
          },
        })
        .pipe(map(res => res.data));
    }
  }

  getByUrl(friendlyUrl: string) {
    return this.http.get<ApiResponse<Set[]>>(
      this.apiPath + '/friendly-url/' + friendlyUrl + '?origin=kiosk'
    );
  }

  getDocumentPreviewUrl(url: string) {
    const customerId = this.customer.getActualCustomer?._id;
    const kioskReference = [
      this.currentSet.company._id,
      this.currentSet.details.UNIQUE_HASH,
      customerId,
    ].join(':');
    return this.http.get<ApiResponse<string>>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'kiosk-reference': btoa(kioskReference),
      }),
    });
  }

  existsSet(setId: string) {
    // HEAD /set?_id=[_id]&origin=kiosk
    return this.http.head(this.apiPath + '?' + setId + '&origin=kiosk');
  }
}
